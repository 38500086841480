import React, { useEffect } from 'react';
import scrollToTop from '../helpers/scrollToTop';
function PrivacyPolicy() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> January 14, 2025
      </p>
      <p>
        <strong>Alexiev's Barbershop</strong> ("we," "our," or "us") is committed
        to protecting your privacy. This Privacy Policy explains how we collect,
        use, disclose, and safeguard your information when you visit our website{" "}
        <a href="https://alexievsbarbershop.com">https://alexievsbarbershop.com</a>{" "}
        ("Site") and use our services. Please read this policy carefully. If you
        do not agree with the terms of this Privacy Policy, please do not access
        the Site.
      </p>
      <h2>1. Information We Collect</h2>
      <p>
        - <strong>Personal Data:</strong> We may collect personally identifiable
        information, such as your name, email address, phone number, and any
        other information you voluntarily provide to us when you book an
        appointment or contact us through the Site.
      </p>
      <p>
        - <strong>Usage Data:</strong> Our Site may collect information about
        your interactions with the Site, including your IP address, browser
        type, operating system, access times, and pages viewed. This information
        helps us understand how visitors use our Site and improve its
        functionality.
      </p>
      <h2>2. Use of Your Information</h2>
      <p>
        We use the information we collect to:
        <ul>
          <li>Provide and manage our services, including appointment bookings and customer support.</li>
          <li>Improve and personalize your experience on our Site.</li>
          <li>Communicate with you, including responding to your inquiries and sending you updates about our services.</li>
          <li>Monitor and analyze usage and trends to enhance your experience and the Site's performance.</li>
        </ul>
      </p>
      <h2>3. Disclosure of Your Information</h2>
      <p>
        We may share your information in the following situations:
        <ul>
          <li>
            <strong>Service Providers:</strong> We may share your information
            with third-party vendors who perform services on our behalf, such as
            appointment scheduling platforms or email service providers.
          </li>
          <li>
            <strong>Legal Obligations:</strong> We may disclose your information
            if required to do so by law or in response to valid requests by
            public authorities.
          </li>
        </ul>
      </p>
      <h2>4. Cookies and Tracking Technologies</h2>
      <p>
        Our Site uses cookies to enhance your browsing experience. Cookies are
        small data files stored on your device. You can set your browser to
        refuse cookies or alert you when cookies are being used. However, if you
        disable cookies, some parts of the Site may not function properly.
      </p>
      <h2>5. Security of Your Information</h2>
      <p>
        We implement reasonable security measures to protect your personal
        information. However, no method of transmission over the internet or
        electronic storage is completely secure, and we cannot guarantee
        absolute security.
      </p>
      <h2>6. Your Data Protection Rights</h2>
      <p>
        Depending on your location, you may have the following rights regarding
        your personal data:
        <ul>
          <li>The right to access – You have the right to request copies of your personal data.</li>
          <li>The right to rectification – You have the right to request that we correct inaccurate information.</li>
        </ul>
      </p>
      <p>
        For more information or questions, contact us at alexievsbarbershop@alexievsbarbershop.com.
      </p>
    </div>
  );
};


export default PrivacyPolicy;
