import VideoBanner from "./components/VideoBanner";
import ImageCarousel from "./components/ImageCarousel";
import BarberServices from "./components/BarberServices";
import { useEffect } from "react";
import scrollToTop from "./helpers/scrollToTop";
import { motion } from "framer-motion";
import saloon from './images/saloon.jpg';

function App() {
  useEffect(() => {
    scrollToTop();
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 50, 
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: -50,
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
  };

  return (
    <motion.div
      className="relative"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <VideoBanner />
      <h2 className="text-center py-4 text-6xl text-red-900">Alexiev's Barbershop</h2>
      <p className="text-center text-xl">Най-високо качество на достъпни цени</p>
      
      <section className="py-4">
        <ImageCarousel />
      </section>

      <section className="py-4 gap-4 flex justify-center relative text-neutral-950">
        <h1 className="text-9xl hidden lg:block absolute top-52 -left-48 text-red-900 rotate-90 pl-[7.5%]">За Нас</h1>
        <div className="flex flex-col gap-4 px-4">
          <p className="text-4xl">Кои сме ние?</p>
          <p className="max-w-lg mx-auto text-xl">
            "Бръснарница Алексиеви" е семейно дело, създадено с грижа и страст от двама братя – Емил и Боян Алексиеви. Със своята дългогодишна практика и стремеж към съвършенство, ние предлагаме на клиентите си не просто подстригване, а изживяване. Всяка визия е внимателно съобразена с индивидуалните желания и стил на клиента, за да подчертаем най-доброто от тях.
          </p>
          <p className="max-w-lg mx-auto text-xl">
            Стремим се към уютна атмосфера и перфектен резултат всеки път. Добре дошли в нашата бръснарница – мястото, където традицията среща модерния стил.
          </p>
        </div>
        <img
          className="w-1/4 grayscale object-cover hidden md:block"
          src={saloon}
          alt="Barber Shop"
        />
      </section>

      <section className="w-xl pb-4 px-4 bg-red-950 bg-opacity-10 mt-4 flex flex-col md:flex-row mx-auto justify-center items-center gap-12">
        <BarberServices />
        {/* <SalonServices /> */}
      </section>
    </motion.div>
  );
}

export default App;
