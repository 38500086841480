import React, { useEffect } from 'react';
import scrollToTop from '../helpers/scrollToTop';

function CookiePolicy() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <h1>Cookie Policy</h1>
      <p>
        <strong>Effective Date:</strong> January 14, 2025
      </p>
      <p>
        This Cookie Policy explains how <strong>Alexiev's Barbershop</strong> ("we," "our," or "us") uses cookies and
        similar technologies to enhance your browsing experience on our website,{" "}
        <a href="https://alexievsbarbershop.com">https://alexievsbarbershop.com</a>{" "}
        ("Site").
      </p>
      <h2>1. What Are Cookies?</h2>
      <p>
        Cookies are small text files stored on your device when you visit a website.
        They help websites remember information about your visit, such as your preferences,
        and can enhance your overall experience.
      </p>
      <h2>2. Types of Cookies We Use</h2>
      <ul>
        <li>
          <strong>Essential Cookies:</strong> These cookies are necessary for the website
          to function properly. They enable core functionalities like navigation.
        </li>
        <li>
          <strong>Performance Cookies:</strong> These cookies help us understand how users
          interact with our website by collecting information such as page load times and errors.
        </li>
        <li>
          <strong>Functional Cookies:</strong> These cookies remember your preferences
          and settings to improve your experience.
        </li>
        <li>
          <strong>Advertising Cookies:</strong> These cookies provide personalized ads based
          on your browsing behavior.
        </li>
      </ul>
      <h2>3. Managing Your Cookies</h2>
      <p>
        You can manage or disable cookies in your browser settings. Please note that
        disabling cookies may impact the functionality of the Site.
      </p>
      <h2>4. Third-Party Cookies</h2>
      <p>
        We may allow third-party services, such as analytics providers or advertisers,
        to place cookies on your device. These cookies are subject to the third-party
        provider's policies.
      </p>
      <h2>5. Changes to This Cookie Policy</h2>
      <p>
        We may update this Cookie Policy from time to time. Changes will be posted
        on this page with a revised "Effective Date."
      </p>
      <p>
        For questions or concerns about this Cookie Policy, contact us at alexievsbarbershop@alexievsbarbershop.com.
      </p>
    </div>
  );
}


export default CookiePolicy;
