import React, { useEffect } from 'react';
import scrollToTop from '../helpers/scrollToTop';
import about from '../images/about.jpg'
import { motion } from 'framer-motion';
import saloonOutside from '../images/2023-11-06.jpg'
import saloon from '../images/saloon.jpg'
function About() {
  useEffect(() => {
    scrollToTop();
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 50,
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: -50,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  };

  return (
    <motion.div
      className="min-h-screen"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="relative">
        <img
          className="brightness-75 grayscale object-cover h-[40vh] object-left-bottom w-full"
          src={about}
          alt="About Banner"
        />
        <h2
  className="absolute h-full top-0 flex items-center left-1/2 -translate-x-1/2 text-center py-4 text-6xl text-red-800">
  За нас
</h2>

      </div>

      <section className="py-20 gap-4 flex justify-center relative text-neutral-950">
        <h2 className="text-9xl hidden lg:block absolute top-52 -left-48 text-red-900 rotate-90"></h2>
        <img
          src={saloonOutside}
          className="w-1/4 grayscale object-cover hidden md:block"
          alt="Barber Image"
        />
        <div className="flex flex-col gap-4 px-4">
          <p className="text-4xl">Откъде започна всичко</p>
          <p className="">Алексиеви | 2023</p>
          <p className="max-w-lg mx-auto text-xl">
          Историята на <strong>ALEXIEV’S BARBERSHOP</strong> започва с една детска мечта. Още от 8-годишна възраст, Емил Алексиев знаеше, че иска да бъде бръснар. Започна с подстригването на плюшени играчки и приятели, което постепенно се превърна в сериозна страст.
          </p>
          <p className="max-w-lg mx-auto text-xl">
          На 18 години той реши да направи голямата крачка, като събра пари за професионален курс и започна работа в бръснарница, за да натрупа опит и усъвършенства уменията си.
          </p>
          <p className="max-w-lg mx-auto text-xl">
          С времето и със събраните средства, Емил и брат му откриха <strong>ALEXIEV’S BARBERSHOP</strong>. Днес те с гордост управляват своята бръснарница, продължават да се развиват и се стремят към съвършенство, следвайки страстта си към занаята.
          </p>
        </div>
      </section>

      <section className="py-20 gap-4 flex justify-center relative text-neutral-950 bg-red-950 bg-opacity-10">
        <h2 className="text-9xl hidden lg:block absolute top-64 -left-48 text-red-900 rotate-90 pl-[5%]">История</h2>
        <div className="flex flex-col gap-4 px-4">
          <p className="text-4xl">Кои Сме Ние?</p>
          <p className="">Отдадени Професионалисти</p>
          <p className="max-w-lg mx-auto text-xl">
            "Бръснарница Алексиеви" е семейно дело, създадено с грижа и страст от двама братя – Емил и Боян Алексиеви.
          </p>
          <p className="max-w-lg mx-auto text-xl">
            Със своята дългогодишна практика и стремеж към съвършенство, ние предлагаме на клиентите си не просто подстригване, а изживяване. Всяка визия е внимателно съобразена с индивидуалните желания и стил на клиента, за да подчертаем най-доброто от тях.
          </p>
          <p className="max-w-lg mx-auto text-xl">
            Стремим се към уютна атмосфера и перфектен резултат всеки път. Добре дошли в нашата бръснарница – мястото, където традицията среща модерния стил.
          </p>
        </div>
        <img
          src={saloon}
          className="w-1/4 object-cover hidden md:block"
          alt="Barber Shop"
        />
      </section>
    </motion.div>
  );
}

export default About;
